<template>
  <v-dialog v-model="detailsDialog" max-width="800" style="z-index: 9999;">
    <v-card>
      <v-card-title>
        <span class="headline text-gray-700">Detalhes da Transação</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <div>
          <div class="section-header">Detalhes da Venda</div>
          <div class="totals">
            <div class="total-item total-item-alt">
              <span><strong>Pedido</strong></span>
              <span class="spanValue">BEE{{ (selectedTransaction.id + "").padStart(6, "0") }}</span>
            </div>
            <div class="total-item">
              <span><strong>Cliente</strong></span>
              <span class="spanValue">{{ selectedTransaction.name }}</span>
            </div>
            <div class="total-item total-item-alt">
              <span><strong>Email</strong></span>
              <span class="spanValue">{{ selectedTransaction.email }}</span>
            </div>
            <div class="total-item">
              <span><strong>Telefone</strong></span>
              <span class="spanValue">{{ selectedTransaction.phone }}</span>
            </div>
            <div class="total-item total-item-alt" v-if="selectedTransaction.subtotal !== undefined">
              <span><strong>Subtotal</strong></span>
              <span class="spanValue">{{
                formatCurrency(selectedTransaction.subtotal) }}</span>
            </div>
            <div class="total-item" v-if="selectedTransaction.shipping !== undefined">
              <span><strong>Frete</strong></span>
              <span class="spanValue">{{
                formatCurrency(selectedTransaction.shipping) }}</span>
            </div>
            <div class="total-item" v-if="selectedTransaction.total !== undefined">
              <span><strong>Total</strong></span>
              <span class="spanValue">{{
                formatCurrency(selectedTransaction.total) }}</span>
            </div>
          </div>
          <div class="section-header">Despesas</div>
          <div class="totals">
            <div class="total-item total-item-alt" v-if="selectedTransaction.transactionFee !== undefined">
              <span><strong>Taxa de Transação</strong></span>
              <span class="spanValue">{{
                formatCurrency(selectedTransaction.transactionFee) }}</span>
            </div>
            <div class="total-item" v-if="selectedTransaction.invoiceFee !== undefined">
              <span><strong>Taxa de Emissão de Nota Fiscal</strong></span>
              <span class="spanValue">{{
                formatCurrency(selectedTransaction.invoiceFee) }}</span>
            </div>
            <div class="total-item total-item-alt"
              v-if="selectedTransaction.invoiceFee && selectedTransaction.transactionFee !== undefined">
              <span><strong>Total Despesas</strong></span>
              <span class="spanValue">{{
                formatCurrency(calculateExpenses(selectedTransaction)) }}</span>
            </div>
          </div>
          <div class="section-header">Lucro</div>
          <div class="totals">
            <div class="total-item total-item-alt" v-if="selectedTransaction.costPrice !== undefined">
              <span><strong>Lucro Bruto</strong></span>
              <span class="spanValue">{{
                formatCurrency(calculatedSubProfit(selectedTransaction)) }}</span>
            </div>
            <div class="total-item"
              v-if="selectedTransaction.costPrice !== undefined && selectedTransaction.transactionFee !== undefined">
              <span><strong>Lucro Líquido</strong></span>
              <span class="spanValue">{{ formatCurrency(calculatedSubProfit(selectedTransaction) -
                calculatedProfit(selectedTransaction)) }}</span>
            </div>
          </div>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="#00AF7D" text @click="closeDialog">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'TransactionDetailsDialog',
  props: {
    detailsDialog: Boolean,
    selectedTransaction: Object,
  },
  methods: {
    formatCurrency(value) {
      return value.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    calculateExpenses(transaction) {
      return transaction.invoiceFee + transaction.transactionFee
    },
    calculatedSubProfit(transaction) {
      return transaction.subtotal - transaction.costPrice;
    },
    calculatedProfit(transaction) {
      return transaction.invoiceFee - transaction.transactionFee;
    },
    closeDialog() {
      this.$emit('close-dialog');
    },
  },
};
</script>

<style scoped>
.section-header {
  background-color: #00af7dba;
  padding: 10px;
  font-weight: bold;
  margin-bottom: 10px;
}

.totals {
  padding: 10px;
  background-color: #fff;
}

.total-item {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
}

.total-item-alt {
  background-color: #f9f9f9;
}

.spanValue {
  text-align: right;
}
</style>
