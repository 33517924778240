<template>
  <v-container class="container-page">
    <v-row v-if="!domainExists">
      <v-col cols="12">
        <v-row>
          <v-col cols="12" v-if="isInitialView">
            <h2>Contrate um Domínio</h2>
            <div class="card-plans">
              <v-container>
                <v-row justify="center">
                  <DomainPlanCard price="R$ 199,90" type="Anual" @select-domain="selectDomain" />
                </v-row>
              </v-container>
            </div>
          </v-col>

          <v-col cols="12" v-if="domainSelected && !domainData">
            <v-container>
              <v-row>
                <DomainSummary :price="domainSelected.price" :type="domainSelected.type" />
                <DomainConfiguration :loading="loading" :domainName="paymentData.domainName"
                  :domainExtension="paymentData.domainExtension" @update-domain-data="updatePaymentData"
                  @subscribe="subscribe" />
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <Dashboard v-if="domainExists" />

    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title class="text-center">SOLICITAÇÃO RECEBIDA</v-card-title>
        <v-card-text class="text-center">
          {{ successMessage }}
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="pink" text @click="closeDialog">Entendi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Loading v-if="loadingPage" />
  </v-container>
</template>

<script>
import axios from 'axios';
import { mask } from 'vue-the-mask';
import config from '@/store/config';
import Loading from '@/components/Loading.vue';
import DomainPlanCard from '@/components/Domain/DomainPlanCard.vue';
import DomainSummary from '@/components/Domain/DomainSummary.vue';
import DomainConfiguration from '@/components/Domain/DomainConfiguration.vue';
import Dashboard from '@/components/Domain/Dashboard.vue';

const api = axios.create({ baseURL: config.baseURL });

export default {
  name: 'DomainsPage',
  components: { Loading, DomainPlanCard, DomainSummary, DomainConfiguration, Dashboard },
  directives: { mask },
  data() {
    return {
      paymentData: { domainName: '', domainExtension: '.com' },
      loading: false,
      loadingPage: false,
      domainData: null,
      domainSelected: null,
      userData: {},
      storeData: {},
      dialog: false,
      successMessage: '',
      urlStore: '',
      domainExists: false
    };
  },
  computed: {
    isInitialView() {
      return !this.domainSelected && !this.domainData;
    },
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      this.loadingPage = true;
      try {
        this.userData = this.$store.getters.getUserData;
        this.storeData = this.$store.getters.getStoreData;

        if (this.testDomain(this.storeData.slug)) {
          // Se o slug contém um ponto, usar como subdomínio
          this.urlStore = `https://${this.storeData.slug}`;
          this.domainExists = true;
        } else {
          // Caso contrário, usar como parte do caminho
          this.urlStore = `https://${config.url}/${this.storeData.slug}`;
          this.domainExists = false;
        }

      } catch (error) {
        console.error('Erro ao inicializar dados:', error);
      } finally {
        this.loadingPage = false;
      }
    },
    testDomain(slug) {
      return slug.includes('.');
    },
    selectDomain(type, price) {
      this.domainSelected = { type, domain_id: this.getDomainId(type), price };
    },
    getDomainId(type) {
      return process.env.VUE_APP_SANDBOX === 'true' ? 12005 : 113253;
    },
    async subscribe() {
      this.loading = true;
      if (!this.paymentData.domainName) {
        this.$toast.error('Digite um domínio');
        this.loading = false;
        return;
      }

      try {
        const domainFull = `${this.paymentData.domainName}${this.paymentData.domainExtension}`;

        if (!domainFull || domainFull === this.paymentData.domainExtension) {
          throw new Error('O domínio completo está vazio ou inválido.');
        }

        const config = await this.$store.getters.getConfig;

        const verificationResponse = await api.post('/domain/request', {
          domain: domainFull
        }, config);

        if (verificationResponse.data.message === "Solicitação enviada com sucesso!") {
          const subscriptionResponse = await api.post('/subscribe', data, config);
          this.handleSubscriptionResponse(subscriptionResponse);
        } else {
          this.$toast.error('Erro ao verificar domínio');
        }
      } finally {
        this.loading = false;
        this.dialog = true;
        this.successMessage = "Sua solicitação de registro de domínio foi recebida. Nossa equipe entrará em contato em até 24h.";
      }
    },
    handleSubscriptionResponse(response) {
      if (response.data.error) {
        const errorMessage = response.data.error_description?.message || response.data.error_description || 'Erro desconhecido';
        this.$toast.error(errorMessage);
        return;
      }
      this.$toast.success('Contratação encaminhada para análise');
      this.updateUserData();
    },
    async updateUserData() {
      try {
        const response = await api.get('/me', await this.$store.getters.getConfig);
        localStorage.setItem('user', JSON.stringify(response.data.user));
        this.$store.commit('update_dados', response.data.user);
      } catch (error) {
        console.error('Erro ao atualizar dados do usuário:', error);
      }
    },
    updatePaymentData(key, value) {
      this.$set(this.paymentData, key, value);
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.card-plans {
  background-color: #1c1a1d;
  color: #fff;
  border-radius: 20px;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 600px;
}
</style>
