<template>
    <v-app>
        <v-container fluid>
            <v-row v-if="isMobile()">
                <v-col>
                    <img src="../assets/Logo Beeasy v1 - CInza SEM FUNDO.svg" class="logo" alt="">
                </v-col>
            </v-row>
            <v-row>
                <v-col class="image" v-if="!isMobile()">
                </v-col>
                <v-col class="login">
                    <div class="card-login">
                        <img src="../assets/Logo Beeasy v1 - CInza SEM FUNDO.svg" class="logo-desk" v-if="!isMobile()" alt="">
                        <h1>Login</h1>
                        <p>Informe seu acesso para entrar</p>
                        <br>
                        <div class="form-item">
                            <label>Email</label>
                            <input type="text" class="input-default" placeholder="teste@gmail.com" v-model="data.email">
                        </div>

                        <div class="form-item">
                            <label>Senha</label>
                            <input type="password" class="input-default" @keypress.enter="login" placeholder="••••••••••" v-model="data.password">
                        </div>

                        <div class="forgot">
                            <a href="">Esqueci minha senha</a>

                        </div>
                        <div class="error-data" v-if="error">
                            <p>Dados inválidos</p>
                        </div>

                        <div class="form-item">
                            <button class="button-default" @click="login" v-if="!loading">Entrar</button>
                            <button class="button-default" disabled v-if="loading">Aguarde...</button>
                        </div>
                        <!-- <div class="register">
                            <p>Não tem uma conta? <a href="" @click.prevent="$router.push(`/register`)">Cadastre-se</a></p>
                        </div> -->
                    </div>
                </v-col>
            </v-row>
        </v-container>
    </v-app>
</template>

<script>
import axios from 'axios'
import config from '@/store/config'

const api = axios.create({
    baseURL: config.baseURL
})


export default {
    name: 'HomePage',
    metaInfo(){
      return{
        title: `Login - ${config.name} - Beeasy`
      }
    },
    data(){
        return {
            loading: false,
            error: false,
            config: {},
            data: {
                email: '',
                password: ''
            }
        }
    },
    async mounted(){
    },
    methods:{
        isMobile() {
            if( screen.width <= 760 ) {
                return true;
            }else {
                return false;
            }
        },
        async login(){
            try {
                if(this.data.email == '' || this.data.password == ''){
                    this.$toast.warning('Preencha todos os campos')
                    return
                }
                this.loading = true;
                let response = await api.post('/auth', this.data)
                this.config = {
                    headers: { Authorization: `Bearer ${response.data.value}` }
                }
                localStorage.setItem('token', response.data.value)
                localStorage.setItem('user', JSON.stringify(response.data.user))
                localStorage.setItem('store', JSON.stringify(response.data.store))
                this.$store.commit('update_dados', response.data.user)
                this.$store.commit('update_store', response.data.store)
                this.$toast.success('Login efetuado com sucesso')
                this.$router.push('/home')
                this.loading = false;
                this.$store.commit(
                    'insertLog',
                    {
                        action: 'Login',
                        description: `[${response.data.user.fullName.toUpperCase()}] realizou login no sistema`,
                    }
                )
               
            } catch (error) {
                this.loading = false;
                this.error = true
            }
        },
        async me(){
            try {
                let response = await api.get('/me', this.$store.getters.getConfig)
                console.log(response.data)
            } catch (error) {
                console.log(error)
            }
        }
    }
}
</script>

<style scoped>

    /* DEFAULT STYLES */
    *{
        font-family: "Outfit", sans-serif;
    }

    .input-default{
        width: 100%;
        padding: .5rem;
        color: #202E33;
        font-size: 1rem;
        font-weight: 400;
        border-radius: 5px;
        border: 1px solid #202E33;

    }

    .button-default{
        width: 100%;
        padding: .5rem;
        color: #fff;
        font-size: 1rem;
        font-weight: 500;
        border-radius: 5px;
        background-color: #202E33; /* #1c1a1d */
        border: 1px solid #202E33; /* #1c1a1d */
        cursor: pointer;
    }



    .logo{
        width: 200px;
        margin: 0 auto;
        display: block;
        margin-top: 2rem;
    }

    .logo-desk{
        width: 200px;
        float: right;
        display: block;
        margin-top: 1rem;
        margin-bottom: 1.5rem;
    }

    .image {
        display: flex;
        flex-direction: column;
        min-height: 100vh;
        background-repeat: no-repeat;
        background-size: cover;
        justify-content: space-between;
        padding-left: 40px;
        background-position: 50%;
        background-image: url("https://firebasestorage.googleapis.com/v0/b/oculos2w-255bd.appspot.com/o/IMAGENS%20PADROES%2FFRANQUEADOS%20(3).png?alt=media&token=83ed24ee-d757-4a6f-aa02-8e79ba559216");
    }

    .login .card-login{
        width: 80%;
        display: block;
        margin: 0 auto;
        margin-top: 2rem;
    }

    .login .form-item{
        margin-bottom: 1rem;
    }

    .login .form-item label{
        font-size: 1rem;
        font-weight: 500;
        color: #202E33;
    }

    .login .form-item button{
        margin-bottom: 5rem;
    }

    .login .forgot a{
        display: block;
        font-size: 16px;
        text-decoration: underline;
        font-weight: medium;
        color: #202E33;
        margin-bottom: 2rem;
    }

    .login .register p{
        font-size: 16px;
        font-weight: 400;
        color: #202E33;
    }
    .login .register p a{
        font-size: 16px;
        font-weight: 500;
        color: #202E33;
    }

    .error-data{
        color: red;
    }
</style>