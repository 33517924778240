<template>
  <v-dialog v-model="mEdit" style="z-index: 9999;" max-width="600px">
    <v-card>
      <v-card-title>
        <span class="text-h5">Editar</span>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="3">
              <img :src="productSelected.src" style="width: 100%;display: block;margin: 0 auto" alt="">
            </v-col>
            <v-col>
              <label for="name">Nome</label>
              <input type="text" id="name" class="input-default"
                v-model="productSelected.name">

              <label for="cost">Preço de Custo</label>
              <input type="text" id="cost" class="input-default" readonly
                :value="parseFloat(productSelected.cost_price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })">

              <label for="sale">Preço de Venda</label>
              <input type="number" id="sale" class="input-default" v-model="productSelected.sale_price">

              <label for="sale">Preço Promocional</label>
              <input type="number" id="sale" class="input-default" v-model="productSelected.promotional_price">
              <small style="color: #000">Deixe o campo vazio para desativar o preço promocional.</small><br>

              <label for="cost">Lucro</label>
              <input type="text" id="cost" class="input-default" readonly
                :value="`${(parseFloat(productSelected.promotional_price ? productSelected.promotional_price : productSelected.sale_price) - (parseFloat(productSelected.cost_price))).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}*`
                ">
                
              * Valor aproximado do lucro (descontando a taxa transacional de até 6% + taxa de emissão de nota fiscal de 10%)<br>
              Você receberá entre <strong>{{ valueMP.toLocaleString('pt-BR',{ style: 'currency', currency: 'BRL' }) }}</strong> e <strong>{{valueEfi.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</strong> por venda.
              <div class="d-flex align-center">
                <v-switch v-model="productSelected.published" inset></v-switch>
                <span v-if="productSelected.published">Publicado</span>
                <span v-else>Não Publicado</span>
              </div><br>
              <small class="text-error">{{ text_error }}</small><br>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <button class="button-link" @click="closeModal">
          Cancelar
        </button>
        <button class="button-default" @click="saveProduct" :disabled="!validated" v-if="!loading">
          Salvar
        </button>
        <button class="button-default" v-if="loading">
          Aguarde...
        </button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'EditProduct',
  props: {
    productSelected: Object,
    mEdit: Boolean,
    loading: Boolean,
  },
  data(){
    return {
      valueMP: 0,
      valueEfi: 0,
      validated: false,
      minimum_sale_price: 0,
      text_error: ''
    }
  },
  created(){
    this.calculate()
  },
  watch: {
    "productSelected.sale_price"(){
      this.calculate()
    },
    "productSelected.promotional_price"(){
      console.log(this.productSelected.promotional_price == 0)
      if(this.productSelected.promotional_price == '' || this.productSelected.promotional_price == '0'){
        this.calculate()
      }else{
        this.calculatePromotionalPrice()
      }
    }
  },
  methods: {
    calculate(){
      this.valueMP = ((parseFloat(this.productSelected.sale_price) - (parseFloat(this.productSelected.cost_price))) - (this.productSelected.sale_price * 0.06) - (this.productSelected.sale_price * 0.10))
      this.valueEfi = ((parseFloat(this.productSelected.sale_price) - (parseFloat(this.productSelected.cost_price))) - 3.45 - (this.productSelected.sale_price * 0.10))
      if(isNaN(this.valueMP) || isNaN(this.valueEfi)){
        this.valueMP = 0
        this.valueEfi = 0
      }

      if(this.valueMP < 0 || this.valueMP < 0){
       this.text_error = 'O valor de lucro a receber não pode ser menor que 0'
        this.validated = false
        return
      } else {
        this.validated = true
        this.text_error = ''
      }

      if(this.productSelected.sale_price == ''){
        this.validated = false
        this.text_error = 'O campo de preço de venda não pode estar vazio'
        return
      }else{
        this.validated = true
      }
    },
    calculatePromotionalPrice(){
      console.log(this.productSelected.promotional_price)
      this.valueMP = ((parseFloat(this.productSelected.promotional_price) - (parseFloat(this.productSelected.cost_price))) - (this.productSelected.promotional_price * 0.06) - (this.productSelected.promotional_price * 0.10))
      this.valueEfi = ((parseFloat(this.productSelected.promotional_price) - (parseFloat(this.productSelected.cost_price))) - 3.45 - (this.productSelected.promotional_price * 0.10))
      if(isNaN(this.valueMP) || isNaN(this.valueEfi)){
        this.valueMP = 0
        this.valueEfi = 0
      }

      if(this.valueMP < 0 || this.valueMP < 0){
       this.text_error = 'O valor de lucro a receber não pode ser menor que 0'
        this.validated = false
        return
      } else {
        this.validated = true
        this.text_error = ''
      }

      if(this.productSelected.promotional_price == ''){
        this.validated = false
        this.text_error = 'O campo de preço de venda não pode estar vazio'
        return
      }else{
        this.validated = true
      }
    },
    saveProduct() {
      const payload = {
        sale_price: this.productSelected.sale_price,
        promotional_price: this.productSelected.promotional_price,
        name: this.productSelected.name,
        published: this.productSelected.published ? 1 : 0
      };
      this.$emit('saveProduct', payload)
    },
    closeModal() {
      this.$emit('closeModal')
    }
  }
}
</script>

<style scoped>
.button-link {
  width: 100%;
  padding: .5rem;
  color: #00AF7D;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.button-default {
  padding: .5rem 1rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
  background-color: #00AF7D;
  border: 1px solid #00AF7D;
  cursor: pointer;
}

.d-flex {
  display: flex;
  align-items: center;
}

.button-default:disabled {
  background-color: #00AF7D;
  border: 1px solid #00AF7D;
  opacity: 0.5;
  cursor: not-allowed;
}

.text-error {
  color: red;
  font-size: 0.8rem;
}
</style>
