<template>
    <v-container fluid class="container-page">
      <v-row>
        <v-col cols="12" md="12">
          <v-row>
            <v-col cols="12" md="12">
              <h2>Solicitações de Saques</h2>
            </v-col>
            
            <v-col cols=12>
              <v-data-table :headers="headers" :items="withdraws" :items-per-page="100000" class="elevation-1">
                
                <template v-slot:[`item.Valor_Solicitado`]="{ item }">
                 {{ item.Valor_Solicitado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
                </template>
  
                <template v-slot:[`item.Saldo_Carteira`]="{ item }">
                 {{ item.Saldo_Carteira.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}
                </template>
  
                <template v-slot:[`item.status`]="{ item }">
                 {{ item.status == 'pending' ? 'Pendente' : item.status == 'approved' ? 'Aprovado' : 'Rejeitado' }}
                </template>
  
                <template v-slot:[`item.actions`]="{ item }">
  
  
                  <v-btn-toggle
                    rounded
                    v-if="item.status == 'pending'"
                  >
                    <v-btn @click="update(item, 'refused')" color="red" small>
                      <v-icon>mdi-close-circle</v-icon>
                    </v-btn>
                    <v-btn @click="update(item, 'approved')" color="green" small>
                      <v-icon>mdi-check-circle</v-icon>
                    </v-btn>
                  </v-btn-toggle>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <Loading v-if="loadingPage" />
    </v-container>
  </template>
  
  <script>
  import Loading from '@/components/Loading.vue'
  import axios from 'axios'
  import config from '@/store/config'
  const api = axios.create({
    baseURL: config.baseURL
  })
  
  
  export default {
    name: 'AdmOrdersPage',
    metaInfo() {
      return {
        title: `Solicitações de Saque - Óculos 2W - Beeasy - ${this.userData.fullName}`
      }
    },
    components: {
      Loading
    },
    data() {
      return {
        page: 1,
        pageCount: 0,
        itemsPerPage: 5,
        headers: [
          {
            text: 'Código Carteira',
            align: 'center',
            sortable: false,
            value: 'id_withdraw_request',
          },
          { text: 'Token', value: 'token', align: 'center', },
          { text: 'Cliente', value: 'Cliente', align: 'center', },
          { text: 'Loja', value: 'Loja', align: 'center', },
          { text: 'Valor Solicitado', value: 'Valor_Solicitado', align: 'center', },
          { text: 'Status', value: 'status', align: 'center', },
          { text: 'Saldo Carteira', value: 'Saldo_Carteira', align: 'center', },
          { text: 'PIX', value: 'PIX_CPF', align: 'center', },
          { text: 'Ações', value: 'actions', align: 'center', },
        ],
        withdraws: [],
        userData: {},
        storeData: {},
        start: '',
        end: '',
        loadingPage: false,
        totalFranchiseeCommissions: 0,
        totalSystemComissions: 0,
        totalBrunoComissions: 0,
        totalCostPrice: 0
      }
    },
    async mounted() {
      await this.init()
    },
    methods: {
      async init() {
        this.userData = this.$store.getters.getUserData
        this.storeData = this.$store.getters.getStoreData
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  
        this.start = firstDay.toISOString().split('T')[0]
        this.end = lastDay.toISOString().split('T')[0]
        await this.getWithdrawsRequests()
        
      },
      async getWithdrawsRequests() {
        try {
          this.withdraws = []
          this.loadingPage = true
          const { data } = await api.get(`/adm/wallet/withdraws`, await this.$store.getters.getConfig)
          this.withdraws = data
          this.loadingPage = false
          
        } catch (error) {
          console.error(error)
        }
      },
      async update(data, status){
        try {
          let c = confirm('Deseja realmente alterar o status?')
          if(!c) return
          this.loadingPage = true
          await api.put(`/wallet/withdraws/${data.id_withdraw_request}`, { status }, await this.$store.getters.getConfig)
          this.getWithdrawsRequests()
          this.loadingPage = false
        } catch (error) {
          console.error(error)
        }
      }
    }
  }
  </script>
  
  <style scoped>
  * {
    font-family: "Outfit", sans-serif;
  }
  
  .text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
  }
  
  .text-gray-700 {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity));
  }
  
  .bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity));
  }
  
  .input-default {
    width: 100%;
    padding: .5rem;
    color: #404049;
    font-size: 1rem;
    font-weight: 400;
    border-radius: 5px;
    border: 1px solid rgba(64, 64, 73, .3);
  }
  
  .button-default {
    width: 100%;
    padding: .5rem;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 5px;
    background-color: #00AF7D;
    border: 1px solid #00AF7D;
    cursor: pointer;
  }
  
  .column-pos {
    text-align: center;
    color: rgb(55 65 81);
    ;
    padding: .5rem;
  }
  
  .column-title {
    font-size: 1rem;
    font-weight: 400;
  }
  
  .column-subtitle {
    font-size: .8rem;
    font-weight: 400;
  }
  </style>
  