<template>
    <div class="card-plans">
        <v-container>
          <v-row>
            <v-col cols="12" md="12" style="text-align: center;">
              <v-btn-toggle
                v-model="type"
                rounded
                color="#00AF7D"
              >
                <v-btn class="button-types" value="monthly">
                  MENSAL
                </v-btn>
                <v-btn class="button-types" value="quarterly">
                  TRIMESTRAL
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" class="plan">
              <h3>Básico</h3>
              <h2 v-if="type == 'monthly'">R$ 350,00</h2>
              <h2 v-if="type == 'quarterly'">R$ 900,00</h2>

              <h3 v-if="type == 'monthly'">Mensal</h3>
              <h3 v-if="type == 'quarterly'">Trimestral</h3>
              
              <ul>
                <li>Site de vendas</li>
                <li>Área restrita</li>
                <li>Treinamentos / Central de Associados</li>
                <li>Suporte e consultoria</li>
                <li>Kit de produtos inicial</li>
                <li class="no">Receba produtos mensalmente</li>
                <li class="no">Produtos personalizados com sua marca</li>
                <li class="no">Receba fotos e vídeos de seus produtos personalizados</li>
              </ul>
              <button class="button-subscribe" @click="selectPlan(type, planBasic, 'basic')">
              Assinar
              </button>
            </v-col>

            <v-col cols="12" md="6" class="plan">
              <h3>Personalizado</h3>
              <h2 v-if="type == 'monthly'">R$ 700,00</h2>
              <h2 v-if="type == 'quarterly'">R$ 1.785,00</h2>

              <h3 v-if="type == 'monthly'">Mensal</h3>
              <h3 v-if="type == 'quarterly'">Trimestral</h3>
              <ul>
                <li>Site de vendas</li>
                <li>Área restrita</li>
                <li>Treinamentos / Central de Associados</li>
                <li>Suporte e consultoria</li>
                <li>Kit de produtos inicial</li>
                <li>Receba produtos mensalmente</li>
                <li>Produtos personalizados com sua marca</li>
                <li>Receba fotos e vídeos de seus produtos personalizados</li>
              </ul>
              <button class="button-subscribe" @click="selectPlan(type, planPersonalized, 'personalized')">
              Assinar
              </button>
            </v-col>
          </v-row>
        </v-container>
    </div>
</template>

<script>
export default {
    name: 'CardPlans',
    data(){
        return {
            type: 'monthly',
            planBasic: {
                monthly: 350,
                quarterly: 900,
            },
            planPersonalized: {
                monthly: 700,
                quarterly: 1785,
            
            }
        }
    },
    methods: {
        selectPlan(recurrence, plan, type) {
          recurrence = recurrence == 'monthly' ? 'Mensal' : 'Trimestral'
          let price = recurrence == 'Mensal' ? plan.monthly : plan.quarterly
          type = type == 'basic' ? 'Básico' : 'Personalizado'
          
          this.$emit('selectPlan', { recurrence, price, type })
        }
    }
}
</script>

<style scoped>

.input-type {
  width: 100%;
  padding: .5rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 5px;
  background-color: #1c1a1d;
  border: 1px solid #fff;

}

.card-plans {
  background-color: #1c1a1d;
  color: #fff;
  border-radius: 20px;
  padding: 15px;
}

.card-plans .plan {
  padding: 20px;
}

.card-plans .plan h2 {
  font-size: 2.3rem;
  font-weight: 700;
  color: #00AF7D;
}

.card-plans .plan h3 {
  font-size: 1.8rem;
  font-weight: 700;
  color: #fff;
  margin-top: -10px;
}

.card-plans .plan p {
  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;
  margin-top: 10px;
}

.card-plans .plan ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.card-plans .plan ul li:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('@/assets/check.svg') no-repeat 50%;
  background-size: cover;
  margin-right: 10px;
}

.card-plans .plan ul li.no:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('@/assets/uncheck.svg') no-repeat 50% !important;
  background-size: cover;
  margin-right: 10px;
}

.card-plans .plan ul li.no {
  text-decoration: line-through
}

.card-plans .plan ul li {
  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;
  margin-top: 10px;
}

.button-subscribe {
  width: 100%;
  padding: 10px;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 25px;
  background-color: #00af7d85;
  cursor: pointer;
  margin-top: 20px;
  transition: .4s ease-in-out;
}

.button-subscribe:hover {
  background-color: #00AF7D;
  color: #fff
}

</style>