import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import axios from "axios"
import config from '@/store/config'

const api = axios.create({
  baseURL: config.baseURL
})


export default new Vuex.Store({
  state: {
    userData: {
      "id": 0,
        "idStore": 0,
        "fullName": "",
        "email": "",
        "whatsapp": "",
        "password": "",
        "role": "",
        "chargeId": 0,
        "nextDueDate": "",
        "paymentProvider": "",
        "chargeStatus": "",
        "remember": 0,
        "rank": 0,
        "createdAt": "",
        "updatedAt": "0"
    },
    storeData: {
      "id": 0,
      "name": "",
      "cpf": null,
      "slug": "",
      "primaryColor": "",
      "secondaryColor": "",
      "logo": "",
      "topCase": "",
      "facebookLink": null,
      "instagramLink": null,
      "twitterLink": null,
      "youtubeLink": null,
      "tiktokLink": null,
      "createdAt": null,
      "updatedAt": null
    },
    walletData:{
      "toRelease": 0,
      "balance": 0
    },
    api: 'http://localhost:3333/',
  },
  mutations: {
    update_dados(state, payload) {
      state.userData = payload
    },
    update_store(state, payload) {
      state.storeData = payload
    },
    update_api(state, payload) {
      state.api = payload
    },
    update_walletData(state, payload) {
      state.walletData = payload
    },
    async insertLog(state, data){
      var ipdata = {}
      if(localStorage.getItem('ipdata')){
        ipdata = JSON.parse(localStorage.getItem('ipdata'))
      }else{
        let response = await axios.get("https://ipgeolocation.abstractapi.com/v1/?api_key=4afbf62ff3024a9f9e8e3e22ef334955")
        ipdata = response.data
        localStorage.setItem('ipdata', JSON.stringify(ipdata))
      }
      let ua = `${ipdata.city}/${ipdata.region} - ${navigator.userAgent} - ${localStorage.getItem('token')}`
      let log = {
        id_user: state.userData.id,
        action: data.action,
        description: data.description,
        ip: ipdata.ip_address,
        user_agent: ua
      }
      await api.post("logs", log, await this.getters.getConfig)
    }
  },
  actions: {
    update_dados(state, payload) {
      state.userData = payload
    }
  },
  getters: {
    getUserData: state => {
      return state.userData
    },
    getStoreData: state => {
      return state.storeData
    },
    getWalletData: state => {
      return state.walletData
    },
    isMobile(){
      return window.innerWidth < 768
    },
    async getConfig(){
      return {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
      }
    }
  }
});
