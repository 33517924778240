<template>
    <v-row>
        <v-col cols="12">
            <v-row>
                <v-col cols="12" md="3">
                    <CardInfo :userData="userData" :storeData="storeData" icon="Produtos.svg" title="Produtos" :value="dashboardData.Total_Products" />
                </v-col>
                <v-col cols="12" md="3">
                    <CardInfo :userData="userData" :storeData="storeData" icon="Vendas finalizadas.svg" title="Vendas Finalizadas" :value="dashboardData.Sales_Completed" />
                </v-col>
                <v-col cols="12" md="3">
                    <CardInfo :userData="userData" :storeData="storeData" icon="Vendas canceladas.svg" title="Vendas Canceladas" :value="dashboardData.Canceled_Sales" />
                </v-col>
                <v-col cols="12" md="3">
                    <CardInfo :userData="userData" :storeData="storeData" icon="Site.svg" title="Visitas Site" :value="dashboardData.Website_Visits" />
                </v-col>
            </v-row>
        </v-col>
        <v-col cols="12" md="6">
            <v-card>
                <v-card-title>
                    <h3 class="text-gray-700">Atualizações de Produtos</h3>
                </v-card-title>
                <v-card-text style="height: 70vh;overflow-y: scroll;" v-if="productsNews != null">
                    <v-timeline align-top dense>
                        <v-timeline-item v-for="(product, index) in productsNews" :key="index"
                            :color="`${product.previous_price ? 'yellow' : 'green'}`" small>
                            <v-row class="pt-1">
                                <v-col cols="3">
                                    <strong>{{ new Date(product.updated_at).toLocaleDateString() }}</strong>
                                </v-col>
                                <v-col>
                                    <strong v-if="!product.previous_price">Novo Produto</strong>
                                    <strong v-if="product.previous_price">Alteração de produto</strong>
                                    <div class="text-caption mb-2" v-if="product.previous_price">
                                        O Valor de <strong>CUSTO</strong> produto <strong>{{ product.name }}</strong>
                                        foi alterado de <strong>{{ product.previous_price.toLocaleString('pt-BR',
                                            { style: 'currency', currency: 'BRL' }) }}</strong> para <strong>{{
                                                product.cost_price.toLocaleString('pt-BR', {
                                                    style: 'currency', currency:
                                                        'BRL'
                                                }) }}</strong>
                                    </div>
                                    <div class="text-caption mb-2" v-if="!product.previous_price">
                                        O Produto <strong>{{ product.name }}</strong> foi adicionado ao catálogo<br>
                                        Valor de custo: <strong>{{ product.cost_price.toLocaleString('pt-BR', {
                                            style:
                                                'currency', currency: 'BRL'
                                        }) }}</strong><br>
                                        Valor de venda: <strong>{{ product.sale_price.toLocaleString('pt-BR', {
                                            style:
                                                'currency', currency: 'BRL'
                                        }) }}</strong>
                                    </div>
                                    <v-avatar>
                                        <v-img :src="product.src"></v-img>
                                    </v-avatar>
                                    <v-btn color="#00AF7D" style="float: right;" text
                                        @click="prepareEdit(product)">Revisar</v-btn>
                                </v-col>
                            </v-row>
                        </v-timeline-item>


                    </v-timeline>
                </v-card-text>
                <v-card-text style="height: 70vh;overflow-y: scroll;" v-if="productsNews == null">
                    <v-container>
                        <v-row>
                            <v-col>
                                <section class="dots-container">
                                    <div class="dot"></div>
                                    <div class="dot"></div>
                                    <div class="dot"></div>
                                    <div class="dot"></div>
                                    <div class="dot"></div>
                                </section>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>

        </v-col>
        <EditProduct :productSelected="productSelected" :mEdit="mEdit" :loading="loading" @saveProduct="saveProduct" />

    </v-row>
</template>

<script>
import EditProduct from '@/components/Product/EditProduct.vue'
import CardInfo from '@/components/CardInfo.vue'
import axios from 'axios'
import config from '@/store/config'
const api = axios.create({
    baseURL: config.baseURL
})

export default {
    name: 'Dashboard',
    data() {
        return {
            mEdit: false,
            productSelected: {},
            loading: false,
            dashboardData: {}
        }
    },
    props: {
        userData: Object,
        storeData: Object,
        productsNews: Array | null,
    },
    created() {
        this.dashboard()
        this.$store.commit('insertLog',{action: 'Navegacao',  description: `[${this.userData.fullName.toUpperCase()}] acessou o Dashboard.`})
    },
    components: {
        EditProduct,
        CardInfo
    },
    methods: {
        async prepareEdit(product) {
            this.productSelected = {}
            this.productSelected = product
            this.mEdit = true
            this.$store.commit('insertLog',{action: 'Edicao',  description: `[${this.userData.fullName.toUpperCase()}] clicou em revisar o produto [${product.name}].`})
        },
        async saveProduct() {
            this.loading = true
            try {
                let response = await api.put(`/store/${this.userData.idStore}/products/${this.productSelected.store_product_id}`, { sale_price: this.productSelected.sale_price }, await this.$store.getters.getConfig)
                if (response.status == 200) {
                    this.mEdit = false
                    this.loading = false
                    this.$emit('updateProducts')
                    this.$store.commit('insertLog',{ action: 'Edicao',  description: `[${this.userData.fullName.toUpperCase()}] alterou o valor de venda do produto para [${response.data.sale_price}].`})
                }
            } catch (error) {
                console.log(error);
                this.loading = false
            }
        },

        async saveProduct(payload) {
            try {
                this.loading = true;
                let response = await api.put(`/store/${this.userData.idStore}/products/${this.productSelected.store_product_id}`, payload, await this.$store.getters.getConfig);

                if (response.status == 200) {
                    this.mEdit = false
                    this.loading = false
                    this.$emit('updateProducts')
                    this.$store.commit('insertLog',{ action: 'Edicao',  description: `[${this.userData.fullName.toUpperCase()}] alterou o valor de venda do produto para [${response.data.sale_price}].`})
                }
            } catch (error) {
                console.log(error);
                this.loading = false
            }
        },
        async dashboard(){
            try {
                let response = await api.get(`/dashboard`, await this.$store.getters.getConfig)
                if (response.status == 200) {
                    this.dashboardData = response.data
                }
            } catch (error) {
                console.log(error)
            }
        }
    }
}
</script>

<style>
* {
    font-family: "Questrial", sans-serif;
}

.text-gray-500 {
    --tw-text-opacity: 1;
    color: rgb(107 114 128 / var(--tw-text-opacity));
}

.text-gray-700 {
    --tw-text-opacity: 1;
    color: rgb(55 65 81 / var(--tw-text-opacity));
}

.bg-gray-100 {
    --tw-bg-opacity: 1;
    background-color: rgb(243 244 246 / var(--tw-bg-opacity));
}

.input-default {
    width: 100%;
    padding: .5rem;
    color: #404049;
    font-size: 1rem;
    font-weight: 400;
    border-radius: 5px;
    border: 1px solid rgba(64, 64, 73, .3);

}

.button-default {
    width: 100%;
    padding: .5rem;
    color: #fff;
    font-size: 1rem;
    font-weight: 500;
    border-radius: 5px;
    background-color: #f70293;
    border: 1px solid #f70293;
    cursor: pointer;
}


/* LOADING */
.dots-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.dot {
    height: 20px;
    width: 20px;
    margin-right: 10px;
    border-radius: 10px;
    background-color: #b3d4fc;
    animation: pulse 1.5s infinite ease-in-out;
}

.dot:last-child {
    margin-right: 0;
}

.dot:nth-child(1) {
    animation-delay: -0.3s;
}

.dot:nth-child(2) {
    animation-delay: -0.1s;
}

.dot:nth-child(3) {
    animation-delay: 0.1s;
}

@keyframes pulse {
    0% {
        transform: scale(0.8);
        background-color: #b3d4fc;
        box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
    }

    50% {
        transform: scale(1.2);
        background-color: #6793fb;
        box-shadow: 0 0 0 10px rgba(178, 212, 252, 0);
    }

    100% {
        transform: scale(0.8);
        background-color: #b3d4fc;
        box-shadow: 0 0 0 0 rgba(178, 212, 252, 0.7);
    }
}






.card {
    --primary-clr: #f70293;
    --dot-clr: #FFDC28;
    --play: #FFDC28;
    width: 100%;
    height: 170px;
    border-radius: 10px;
}

.card {
    font-family: "Arial";
    color: rgb(255, 255, 255);
    display: grid;
    cursor: pointer;
    grid-template-rows: 50px 1fr;
}

.card:hover .img-section {
    transform: translateY(1em);
}

.card-desc {
    border-radius: 10px;
    padding: 15px;
    position: relative;
    top: -10px;
    display: grid;
    gap: 10px;
    background: #00AF7D;
}

.card-time {
    font-size: 1.7em;
    font-weight: 500;
}

.img-section {
    transition: 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    background: #FFDC28;
    color: #000;
    padding: 5px;
    font-size: 1.2em;
}

.img-section img {
    width: 10%;
    float: left;
}

.card-header {
    display: flex;
    align-items: center;
    width: 100%;
}

.card-title {
    flex: 1;
    font-size: 0.9em;
    font-weight: 500;
}

.card-menu {
    display: flex;
    gap: 4px;
    margin-inline: auto;
}

.card svg {
    float: right;
    max-width: 100%;
    max-height: 100%;
}

.card .dot2 {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background: var(--dot-clr);
}

.card .recent {
    line-height: 0;
    font-size: 0.8em;
}
</style>