<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
import axios from 'axios'
import config from '@/store/config'

const api = axios.create({
  baseURL: config.baseURL
})
export default {
  name: 'App',
  async mounted() {
    await this.verifyLogged();
  },
  data() {
    return {
      config: {}
    }
  },
  watch: {
    async $route(to, from) {
      try {
        if (!localStorage.getItem('token')) { return; }
        if (this.$route.path == '/register') { return; }
        let me = await this.me()
        if (me.id) {
          if (this.$route.path == '/login' || this.$route.path == '/') {
            this.$router.push('/home')
          }
        } else {
          if (this.$route.path != '/register') {
            this.logout()
            return
          }

        }
      } catch (error) {
        console.log(error)
      }
    }
  },
  methods: {
    async init() {
      if (localStorage.getItem('token')) {
        console.log('init')
        this.$store.commit('update_dados', JSON.parse(localStorage.getItem('user')))
      }
    },
    async verifyLogged() {
      if (localStorage.getItem('token')) {
        this.$store.commit('update_dados', JSON.parse(localStorage.getItem('user')))
        this.config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        let me = await this.me()
        if (me.id) {
          if (this.$route.path == '/login' || this.$route.path == '/') {
            this.$router.push('/home')
          }
        } else {
          this.logout()
          return
        }
      } else {
        if (this.$route.path != '/login') {
          this.$router.push('/login')
        }
        return
      }
    },
    async me() {
      try {
        this.config = {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` }
        }
        let response = await api.get('/me', this.config)

        if (response.data.subscription == null) {
          localStorage.setItem('user', JSON.stringify(response.data.user))
          this.$store.commit('update_dados', JSON.parse(localStorage.getItem('user')))
        }

        if (response.data.walletData) {
          localStorage.setItem('walletData', JSON.stringify(response.data.walletData))
          this.$store.commit('update_walletData', JSON.parse(localStorage.getItem('walletData')))
          console.log('walletData', this.$store.state.walletData)
        }
        if (response.data.user.role == 'admin_store') {
          switch (response.data.planStatus) {
            case 'none':
              if (this.$route.path != '/plans') {
                this.$toast.info('Para começar a configurar sua loja, assine um de nossos planos!', { duration: 10000 })
                this.$router.push('/plans')
              }
              break;
            case 'unpaid':
              if (this.$route.path != '/plans') {
                this.$toast.info('Seu plano está vencido, por favor, atualize seu plano!', { duration: 10000 })
                this.$router.push('/plans')
              }
              break;
            case 'expired':
              if (this.$route.path != '/plans') {
                this.$toast.info('Seu plano está vencido, por favor, atualize seu plano!', { duration: 10000 })
                this.$router.push('/plans')
              }
            break;
            case 'waiting_first_payment':
              if (this.$route.path != '/plans') {
                this.$toast.info('Seu plano está aguardando o primeiro pagamento, por favor, atualize seu plano!', { duration: 10000 })
                this.$router.push('/plans')
              }
              break;
          }
        }


        return response.data.user
      } catch (error) {
        console.log(error)
        if (this.$route.path != '/register') {
          this.logout()
          return
        }
      }
    },
    async logout() {
      localStorage.removeItem('token')
      localStorage.removeItem('user')
      if (this.$route.path != '/login') {
        this.$router.push('/login')
      }
    },
  }
};
</script>
