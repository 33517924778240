<template>
  <v-col cols="12" md="8" class="plan text-start">
    <h2>{{ price }}</h2>
    <h3>{{ type }}</h3>

    <ul>
      <li>Domínio individual 🙋🏻</li>
      <li>Site personalizado com nome próprio 🏆</li>
      <li>Opções <b>.com .com.br</b> ou <b>.shop</b> 🌐</li>
      <li>Garantia ✅</li>
      <li>Credibilidade 🎯</li>
      <li>Validade de 1 ano ✨</li>
      <li>Diferente dos iguais 👊🏻</li>
    </ul>

    <button class="button-subscribe" @click="$emit('select-domain', type, price)">
      Contratar
    </button>
  </v-col>
</template>

<script>
export default {
  name: "DomainPlanCard",
  props: {
    price: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped>
.plan {
  padding: 20px;
  margin: 0 auto;
  text-align: left;
}

.plan h2 {
  font-size: 2.3rem;
  font-weight: 700;
  color: #fff301;
}

.plan h3 {
  font-size: 1.8rem;
  font-weight: 700;
  color: #fff;
  margin-top: -10px;
}

.plan ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.plan ul li:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background: url('@/assets/check.svg') no-repeat 50%;
  background-size: cover;
  margin-right: 10px;
}

.button-subscribe {
  width: 100%;
  padding: 10px;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  border-radius: 25px;
  background-color: #fff2017a;
  cursor: pointer;
  margin-top: 20px;
  transition: .4s ease-in-out;
}

.button-subscribe:hover {
  background-color: #fff201;
  color: #000;
}
</style>
