<template>
  <v-card class="card-product bg-gray-100">
    <v-img :src="product.src" lazy-src="@/assets/loading.gif" class="img"></v-img>
    <v-icon class="icon-edit" @click="$emit('edit', product)">mdi-pencil-outline</v-icon>
    <v-card-text class="card-product-text text-gray-700 bg-gray-100">
      <h2 class="price" v-if="product.promotional_price" style="text-decoration: line-through;font-style: italic;font-size: 16px">{{ formattedSalePrice }}</h2>
      <h2 class="price" v-if="!product.promotional_price">{{ formattedSalePrice }}</h2>
      <h2 class="price" v-if="product.promotional_price" style="font-weight: 700;">{{ formmatedPromotionalPrice }}</h2>
      <p class="sell">Lucro: {{ formattedProfit }}</p>
      <p v-if="product.published" class="status published">Publicado</p>
      <p v-else class="status not-published">Não Publicado</p>
      <p class="name">{{ product.name }}</p>
      <div v-if="product.variants.length > 1">
        <div class="variants-list">
          <span v-for="v in product.variants" :key="v.id">
            <img
              :src="product.images.filter(i => i.id == v.image_id).length > 0 ? product.images.filter(i => i.id == v.image_id)[0].src : product.images[0].src"
              @click="product.src = product.images.filter(i => i.id == v.image_id).length > 0 ? product.images.filter(i => i.id == v.image_id)[0].src : product.images[0].src">
            <div class="variant-stock">{{ v.stock }}</div>
          </span>
        </div>
      </div>
      <div v-else>
        <v-chip class="ma-2" color="#00000099" outlined>
          {{ product.variants[0].stock }}
        </v-chip>
      </div>
      <v-chip v-for="category in product.categories.filter(c => c != null)" :key="category.id" class="ma-2"
        color="#00000099" outlined small>
        {{ category.name }}
      </v-chip>
    </v-card-text>
  </v-card>
</template>



<script>
export default {
  name: 'ProductCard',
  props: {
    product: {
      type: Object,
      required: true
    }
  },
  computed: {
    formattedSalePrice() {
      return parseFloat(this.product.sale_price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    formattedProfit() {
      const profit = parseFloat(this.product.promotional_price ? this.product.promotional_price : this.product.sale_price) - parseFloat(this.product.cost_price);
      return profit.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    formmatedPromotionalPrice() {
      return parseFloat(this.product.promotional_price).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }
  }
};
</script>


<style scoped>
.card-product {
  box-shadow: none !important;
  border: 1px solid rgba(229, 231, 235, 1);
}

.price {
  font-weight: 400;
  font-size: 1.1rem;
  padding-left: 10px;
}

.sell {
  font-size: 0.8rem;
  margin: 0;
  font-weight: 400;
  padding-left: 10px;
}

.name {
  margin: 1rem 0 0 10px;
  font-size: 1.0rem;
  font-weight: 400;
  margin-bottom: 15px;
}

.img {
  position: relative;
}

.icon-edit {
  position: absolute;
  font-size: 1.2rem;
  top: 5px;
  right: 5px;
  color: #00AF7D;
  cursor: pointer;
}

.card-product-text {
  padding: 0.4rem;
}

.status {
  font-size: 0.8rem;
  margin: 0;
  padding-left: 10px;
}

.published {
  color: green;
}

.not-published {
  color: red;
}

.variants-list {
  margin: 0 auto;
  width: 320px;
  overflow: auto;
  white-space: nowrap;
  height: 80px;
}

.variants-list img {
  width: 50px !important;
  height: 50px !important;
  border: 1px solid black;
  margin-right: 15px;
}

.variants-list .variant-stock {
  display: inline-block;
  margin-left: -25px;
  margin-right: 5px;
  margin-top: 34px;
  background-color: #00AF7D;
  color: #fff;
  border-radius: 100%;
  width: 20px;
  height: 20px;
  font-size: 8pt;
  text-align: center;
}
</style>
