<template>
  <v-container fluid class="container-page">
    <v-row>
      <v-col cols="12" md="12">
        <v-row>
          <v-col cols="12" md="12">
            <h2>Cupons de Desconto</h2>
          </v-col>
          <v-col cols="12" md="3" lg="2">
            <button class="button-default" @click="newCoupon">
              <v-icon>
                mdi-plus
              </v-icon>
              NOVO CUPOM
            </button>
          </v-col>
          <v-col cols="12">
            <v-data-table :headers="headers" :items="coupons" :page.sync="page" :items-per-page="itemsPerPage"
              hide-default-footer class="elevation-1" @page-count="pageCount = $event">
              <template v-slot:[`item.code`]="{ item }">
                <div class="column-pos">
                  <span class="column-title">{{ item.code }}</span>
                </div>
              </template>

              <template v-slot:[`item.discount`]="{ item }">
                <div class="column-pos">
                  <span class="column-title" v-if="item.type == 'fixed'">{{ item.discount.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }) }}</span>
                  <span class="column-title" v-if="item.type == 'percentage'">{{ item.discount }}%</span>
                </div>
              </template>

              <template v-slot:[`item.quantity`]="{ item }">
                <div class="column-pos">
                  <h4>{{item.quantity}} / {{ item.quantityUsed }}</h4>
                </div>
              </template>

              <template v-slot:[`item.validUntil`]="{ item }">
                <div class="column-pos">
                  <span class="column-title">{{ new Date(item.validUntil).toLocaleDateString() }}</span>
                </div>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-btn color="#00AF7D" outlined @click="editCoupon(item)">Editar</v-btn>
              </template>
            </v-data-table>
            <!-- <div class="text-center pt-2">
              <v-pagination v-model="page" :length="pageCount"></v-pagination>
              <v-text-field
                    :value="itemsPerPage"
                    label="Produtos por página"
                    type="number"
                    min="-1"
                    max="15"
                    @input="itemsPerPage = parseInt($event, 10)"
                  ></v-text-field>
            </div> -->
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <Loading v-if="loadingPage" />

    <!-- Dialog for Order Details -->
    <v-dialog v-model="dialog" max-width="600" class="dialog-spacing" style="z-index: 1;">
      <v-card>
        <v-card-title class="headline">Detalhes do Pedido</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <label for="name">Código</label>
                <input type="text" id="name" class="input-default" v-model="couponData.code" @keyup="verifyCouponUsed" :readonly="type == 'EDIT'">
                <span v-if="cupomVerifyReturn" style="color: red">{{ verifyCupomReturnText }}</span>
              </v-col>
              <v-col cols="12">
                <label for="discount">Desconto</label>
                <input type="number" id="discount" class="input-default" v-model="couponData.discount" :readonly="type == 'EDIT'">
              </v-col>
              <v-col cols="12">
                <label for="type">Tipo</label>
                <select id="type" class="input-default" v-model="couponData.type" :disabled="type == 'EDIT'">
                  <option value="percentage">Porcentagem</option>
                  <option value="fixed">Valor Fixo</option>
                </select>
              </v-col>
              <v-col cols="12">
                <label for="quantity">Quantidade</label>
                <input type="number" id="quantity" class="input-default" v-model="couponData.quantity">
              </v-col>
              <v-col cols="12">
                <label for="valid_until">Validade</label>
                <input type="date" id="valid_until" class="input-default" v-model="couponData.valid_until">
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <button class="button-link" @click="dialog = false">
            Cancelar
          </button>
          <button class="button-default" @click="saveCoupon" :disabled="!validated" v-if="!loading">
            Salvar
          </button>
          <button class="button-default" v-if="loading">
            Aguarde...
          </button>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Loading from '@/components/Loading.vue'
import axios from 'axios'
import config from '@/store/config'
import moment from 'moment'
const api = axios.create({
  baseURL: config.baseURL
})

export default {
  name: 'CouponsPage',
  metaInfo() {
    return {
      title: `Cupons de Desconto - ${config.name} - Beeasy - ${this.userData.fullName}`
    }
  },
  components: {
    Loading
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      itemsPerPage: 5,
      cupomVerifyReturn: false,
      validated: false,
      loading: false,
      verifyCupomReturnText: "",
      headers: [
        {
          text: 'Código',
          align: 'center',
          sortable: false,
          value: 'code',
        },
        { text: 'Desconto', value: 'discount', align: 'right', },
        { text: 'Quantidade/Usados', value: 'quantity', align: 'center', },
        { text: 'Validade', value: 'validUntil', align: 'center', },
        { text: 'Ações', value: 'actions', align: 'center', sortable: false },
      ],
      coupons: [],
      userData: {},
      storeData: {},
      loadingPage: false,
      dialog: false,
      type: 'NEW',
      couponData: {
        code: "",
        discount: 0,
        type: "percentage",
        quantity: 0,
        valid_until: moment().format('YYYY-MM-DD')
      }
    }
  },
  watch: {
    'couponData.code': function() {
      this.couponData.code = this.couponData.code.toUpperCase()
    }
  },
  async mounted() {
    await this.init()
    this.$store.commit('insertLog',{ action: 'Navegacao',  description: `[${this.userData.fullName}] acessou a página de Cupons de Desconto`})
  },
  methods: {
    async init() {
      this.loadingPage = true
      this.userData = this.$store.getters.getUserData
      this.storeData = this.$store.getters.getStoreData
      await this.getCoupons()
      this.loadingPage = false
    },
    async getCoupons() {
      try {
        const { data } = await api.get(`/coupons`, await this.$store.getters.getConfig)
        this.coupons = data
      } catch (error) {
        console.error(error)
      }
    },
    async verifyCouponUsed() {
      try {

        if(this.couponData.code.length < 5){
          this.cupomVerifyReturn = true
          this.validated = false
          this.verifyCupomReturnText = "O Código deve ter no mínimo 5 caracteres"
          return
        }
        
        let actualCoupon = localStorage.getItem('actualCoupon')

        if(actualCoupon && actualCoupon == this.couponData.code && this.type == 'EDIT'){
          this.cupomVerifyReturn = false
          this.validated = true
          this.verifyCupomReturnText = ""
          return
        }

        let coupons = this.coupons.filter(coupon => coupon.code == this.couponData.code)

        if(coupons.length > 0) {
          this.cupomVerifyReturn = true
          this.validated = false
          this.verifyCupomReturnText = "Cupom já cadastrado"
          return
        }else{
          this.cupomVerifyReturn = false
          this.validated = true
          this.verifyCupomReturnText = ""
        }
      } catch (error) {
        console.error(error)
      }
    },
    async newCoupon(){
      this.type = 'NEW'
      this.couponData = {
        code: "",
        discount: 0,
        type: "percentage",
        quantity: 0,
        valid_until: moment().format('YYYY-MM-DD')
      }
      this.cupomVerifyReturn = false
      this.validated = true
      this.verifyCupomReturnText = ""
      this.dialog = true
      this.$store.commit('insertLog',{ action: 'Insercao',  description: `[${this.userData.fullName}] clicou em novo cupom de desconto`})
    },
    async validate(){
      if(this.couponData.code == ""){
        this.$swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'O campo código é obrigatório!'
        })
        return false
      }

      if(this.couponData.discount == 0){
        this.$swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'O campo desconto é obrigatório!'
        })
        return false
      }

      if(this.couponData.quantity == 0){
        this.$swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'O campo quantidade é obrigatório!'
        })
        return false
      }

      if(this.couponData.valid_until == ""){
        this.$swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'O campo validade é obrigatório!'
        })
        return false
      }

      return true
    },
    async saveCoupon(){
      if(!await this.validate()){
        return
      }
      this.loading = true
      try {
        if(this.type == "NEW"){
          await api.post(`/coupons`, this.couponData, await this.$store.getters.getConfig)
          this.dialog = false
          await this.getCoupons()
          this.$store.commit('insertLog',{ action: 'Insercao',  description: `[${this.userData.fullName}] inseriu um novo cupom de desconto {${this.couponData.code}}`})
        }else{
          await api.put(`/coupons/${localStorage.getItem('editCouponId')}`, this.couponData, await this.$store.getters.getConfig)
          this.dialog = false
          await this.getCoupons()
          this.$store.commit('insertLog',{ action: 'Edicao',  description: `[${this.userData.fullName}] editou o cupom de desconto {${this.couponData.code}}`})
        }
      } catch (error) {
        console.error(error)
      }
      this.loading = false
    },
    editCoupon(item) {
      this.type = 'EDIT'
      this.cupomVerifyReturn = false
      this.validated = true
      this.verifyCupomReturnText = ""
      localStorage.setItem('actualCoupon', item.code)
      localStorage.setItem('editCouponId', item.id)
      this.couponData = {
        code: item.code,
        discount: item.discount,
        type: item.type,
        quantity: item.quantity,
        valid_until: moment(item.validUntil).format('YYYY-MM-DD')
      }
      this.dialog = true
      this.$store.commit('insertLog',{ action: 'Consulta',  description: `[${this.userData.fullName}] acessou detalhes do pedido {${item.id}}`})
    },
    closeDialog() {
      this.dialog = false
      this.selectedOrder = null
    }
  }
}
</script>

<style scoped>
.button-default i{
  color: #fff
}
.button-link {
  width: 100%;
  padding: .5rem;
  color: #00AF7D;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.button-default {
  padding: .5rem 1rem;
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  border-radius: 5px;
  background-color: #00AF7D;
  border: 1px solid #00AF7D;
  cursor: pointer;
}

.button-default:disabled {
  background-color: #00AF7D;
  border: 1px solid #00AF7D;
  opacity: 0.5;
  cursor: not-allowed;
}

* {
  font-family: "Outfit", sans-serif;
}

.spanValue {
  text-align: right;
}

.order-info {
  margin-bottom: 20px;
}

.product-info {
  margin-bottom: 20px;
}

.order-info-item {
  padding: 8px 0;
  background-color: #fff;
}

.order-info-item:nth-child(even) {
  background-color: #f9f9f9;
}

.section-header {
  background-color: #00af7dba;
  padding: 10px;
  font-weight: bold;
  margin-bottom: 10px;
}

.totals {
  padding: 10px;
  background-color: #fff;
}

.total-item {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
}

.total-item-alt {
  background-color: #f9f9f9;
}

.total-item span {
  display: inline-block;
  min-width: 100px;
}

.total-item-bold {
  font-weight: bold;
}

.input-default:read-only {
  background-color: #f9f9f9;
  opacity: 0.7
}
</style>
